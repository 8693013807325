/* eslint-disable react/jsx-no-bind */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';
import Image from 'Component/Image';
import Popup from 'Component/Popup';
import { ProductType } from 'Type/ProductList';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { formatPrice } from 'Util/Price';

import './GiftCardForm.style.scss';

/** @namespace Giftcard/Component/GiftCardForm/Component/GiftCardFormComponent */
export class GiftCardFormComponent extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        updateGiftCardOptions: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        giftCardDesign: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        header_logo_src: PropTypes.string.isRequired
    };

    renderGiftCardForm() {
        const {
            showPopup,
            updateGiftCardOptions,
            product: {
                gift_card_options: {
                    am_giftcard_amount
                }
            }
        } = this.props;

        return (
            <Form
              mix={ { block: 'GiftCardForm', elem: 'FormWrapper' } }
              id="giftCardForm"
            >
                <div block="GiftCardForm" elem="Row">
                    <Field
                      block="GiftCardForm"
                      elem="Input"
                      placeholder={ __('Enter your Name') }
                      name="sender"
                      label={ __('Your Name') }
                      id="sender"
                      type="text"
                      onChange={ (e) => {
                          updateGiftCardOptions({ am_giftcard_sender_name: e });
                      } }
                      validation={ ['notEmpty'] }
                    />
                    <Field
                      block="GiftCardForm"
                      elem="Input"
                      placeholder={ __('Enter Recipient Name') }
                      type="text"
                      name="recipient"
                      label={ __('Recipient Name') }
                      id="recipient"
                      onChange={ (e) => {
                          updateGiftCardOptions({ am_giftcard_recipient_name: e });
                      } }
                      validation={ ['notEmpty'] }
                    />
                </div>
                <div block="GiftCardForm" elem="IndividualRow">
                    <Field
                      block="GiftCardForm"
                      elem="Email"
                      placeholder={ __('Enter Recipient Email') }
                      type="text"
                      name="recipientEmail"
                      id="recipientEmail"
                      label={ __('Recipient Email') }
                      validation={ ['notEmpty', 'email'] }
                      onChange={ (e) => {
                          updateGiftCardOptions({ am_giftcard_recipient_email: e });
                      } }
                    />
                </div>
                <div block="GiftCardForm" elem="IndividualRow">
                    <Field
                      block="GiftCardForm"
                      label={ __('Message') }
                      placeholder={ __('Enter Your Message') }
                      type="textarea"
                      name="gcardMessage"
                      id="gcardMessage"
                      onChange={ (e) => {
                          updateGiftCardOptions({ am_giftcard_message: e });
                      } }
                    />
                </div>
                <div block="GiftCardForm" elem="PreviewButton">
                    <button
                      block="GiftCardForm"
                      elem="Preview"
                      mix={ { block: 'Button', mods: { isHollow: true } } }
                      type="submit"
                      onClick={ am_giftcard_amount ? showPopup : null }
                    >
                        { __('Preview Gift Card') }
                    </button>
                </div>
            </Form>
        );
    }

    renderModalContent() {
        const {
            giftCardDesign,
            product: {
                gift_card_options: {
                    am_giftcard_amount,
                    am_giftcard_sender_name,
                    am_giftcard_message
                }
            },
            header_logo_src,
            currency
        } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return (
            <Popup
              id="GiftCardPreview"
              clickOutside={ false }
              closeButton={ false }
              mix={ { block: 'GiftCardForm', elem: 'Modal' } }
            >
                <div block="GiftCardForm-Modal" elem="Wrapper">
                    <div block="GiftCardForm-Modal-Wrapper" elem="Logo">
                        <Image
                          key="logo"
                          src={ logoSrc }
                        />
                    </div>
                    <div
                      block="GiftCardForm-Modal-Wrapper"
                      elem="Title"
                    >
                        { __('Gift Card') }
                    </div>
                    <div block="GiftCardForm-Modal-Wrapper" elem="Description">
                        <div>
                            { __(
                                '%s purchased a %s gift card for you at Hairy Baby.',
                                am_giftcard_sender_name,
                                am_giftcard_amount
                                    ? formatPrice(am_giftcard_amount, currency) : null
                            ) }
                        </div>
                        <div>
                            { __('Please find below message from %s:', am_giftcard_sender_name) }
                        </div>
                    </div>
                    <div block="GiftCardForm-Modal-Wrapper" elem="Message">
                        { am_giftcard_message || __('Additional message for Gift Card') }
                    </div>
                    <div block="GiftCardForm-Modal-Wrapper" elem="Description">
                        <div>{ __('Please use the following Gift Card code on HairyBaby.com') }</div>
                        <div block="GiftCardForm-Modal-Wrapper" elem="CodeBlock">
                            { __('GiftCardCode') }
                        </div>
                    </div>
                    <div block="GiftCardForm-Modal-Wrapper" elem="Design">
                        <img src={ giftCardDesign } alt="preview" />
                    </div>
                    <div block="GiftCardForm-Modal-Wrapper" elem="Description">
                        <div>{ __('Start shopping now, and apply your gift card code during checkout.') }</div>
                    </div>
                    <button
                      block="GiftCardForm-Modal-Wrapper"
                      mix={ { block: 'Button' } }
                    >
                        { __('Visit Hairy Baby') }
                    </button>
                </div>
            </Popup>
        );
    }

    render() {
        return (
            <>
                { this.renderGiftCardForm() }
                { this.renderModalContent() }
            </>
        );
    }
}

export default GiftCardFormComponent;
