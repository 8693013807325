/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Plisko <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

export class CartQueryPlugin {
    _getAmastyGiftFieldlist() {
        return new Field('gift_card_options')
            .addFieldList(this._getAmastyGiftFields());
    }

    _getAmastyGiftFields() {
        return [
            'am_giftcard_amount',
            'am_giftcard_sender_name',
            'am_giftcard_recipient_name',
            'am_giftcard_recipient_email',
            'am_giftcard_date_delivery',
            'am_giftcard_date_delivery_timezone',
            'am_giftcard_message',
            'am_giftcard_amount_custom',
            'am_giftcard_type',
            'is_date_delivery',
            'am_giftcard_image',
            'am_giftcard_custom_image'
        ];
    }

    _getCartItemFields = (args, callback, instance) => {
        const fields = callback.apply(instance, args);

        fields.push(this._getAmastyGiftFieldlist());

        return fields;
    };
}

export const { _getCartItemFields } = new CartQueryPlugin();

export default {
    'Query/Cart': {
        'member-function': {
            _getCartItemFields: [
                {
                    position: 10,
                    implementation: _getCartItemFields
                }
            ]
        }
    }
};
