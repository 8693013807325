/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Mikheili Papiashvili <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Input from 'Component/FieldInput';
import Html from 'Component/Html';
import Loader from 'Component/Loader';

import { CardType } from '../../type/GiftCard';

import './MyAccountGiftCardInput.style.scss';

/** @namespace Giftcard/Component/MyAccountGiftCardInput/Component/MyAccountGiftCardInputComponent */
export class MyAccountGiftCardInputComponent extends PureComponent {
    static propTypes = {
        handleGiftCardRemove: PropTypes.func.isRequired,
        handleGiftCardSubmit: PropTypes.func.isRequired,
        giftCards: PropTypes.arrayOf(CardType).isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        value: ''
    };

    handleChange = (e) => {
        const { currentTarget: { value } } = e;

        this.setState({ value });
    };

    renderTable(card) {
        const {
            id, code, status, balance, expiredDate
        } = card;

        return (
            <div block="KeyValueTable" elem="Wrapper">
                <table>
                    <thead>
                        <tr block="KeyValueTable" elem="Heading">
                            <th>{ code }</th>
                            { this.renderGiftCardRemoveButton(id) }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{ __('Code') }</th>
                            <td>{ code }</td>
                        </tr>
                        <tr>
                            <th>{ __('Status') }</th>
                            <td>{ status }</td>
                        </tr>
                        <tr>
                            <th>{ __('Balance') }</th>
                            <td>
                                <Html content={ balance } />
                            </td>
                        </tr>
                        <tr>
                            <th>{ __('Expiration Date') }</th>
                            <td>{ expiredDate }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderGiftCardRemoveButton(id) {
        const { handleGiftCardRemove } = this.props;

        return (
            <div block="GiftCard" elem="CardColumn">
                <button onClick={ () => handleGiftCardRemove(id) }>
                    { __('Remove') }
                </button>
            </div>
        );
    }

    renderEnabledGiftCodes() {
        const { giftCards } = this.props;

        if (!giftCards.length) {
            return null;
        }

        return (
            <div block="MyAccountGiftCard" elem="Wrapper">
                { giftCards.map((card) => this.renderTable(card)) }
            </div>
        );
    }

    renderMyAccountGiftCards() {
        const { value } = this.state;
        const { isLoading, handleGiftCardSubmit } = this.props;

        return (
            <div block="MyAccountGiftCardInput" elem="Wrapper">
                <p>{ __('Have a gift voucher?') }</p>
                <Loader isLoading={ isLoading } />
                <Input
                  mix={ { block: 'MyAccountGiftCardInput', elem: 'Input' } }
                  onChange={ this.handleChange }
                  placeholder={ __('Gift Voucher Code') }
                  type="text"
                  value={ value }
                />
                <button
                  block="MyAccountGiftCardInput"
                  elem="Button"
                  disabled={ !value }
                  onClick={ () => handleGiftCardSubmit(value) }
                >
                    { __('Add') }
                </button>
            </div>
        );
    }

    render() {
        return (
            <>
                { this.renderEnabledGiftCodes() }
                { this.renderMyAccountGiftCards() }
            </>
        );
    }
}

export default MyAccountGiftCardInputComponent;
