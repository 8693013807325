/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ('OSL') v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { ProductType } from 'Type/ProductList';
import { formatPrice } from 'Util/Price';

import GiftCardPriceAndStock from '../GiftCardPriceAndStock/GiftCardPriceAndStock.component';

import './GiftCardActions.style';

/** @namespace Giftcard/Component/GiftCardActions/Component/GiftCardActionsComponent */
export class GiftCardActionsComponent extends PureComponent {
    static propTypes = {
        updateGiftCardOptions: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        selectOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })).isRequired,
        selectedValue: PropTypes.string.isRequired,
        setGiftCardValue: PropTypes.func.isRequired,
        configurableVariantIndex: PropTypes.number.isRequired,
        showOnlyIfLoaded: PropTypes.func.isRequired
    };

    renderGiftCardPrice() {
        const {
            product: {
                am_giftcard_prices
            },
            updateGiftCardOptions,
            selectOptions,
            selectedValue,
            setGiftCardValue
        } = this.props;

        if (!am_giftcard_prices || !am_giftcard_prices.length) {
            return null;
        }

        if (am_giftcard_prices.length === 1) {
            updateGiftCardOptions({ am_giftcard_amount: am_giftcard_prices[0].value.value });

            return null;
        }

        return (
            <Field
              id="giftcard-price"
              name="giftcard-price"
              type="select"
              placeholder={ __('Choose Amount') }
              mix={ { block: 'GiftCardActions', elem: 'Price' } }
              selectOptions={ selectOptions }
              value={ selectedValue }
              onChange={ setGiftCardValue }
              isSortingEnabled={ false }
            />
        );
    }

    renderGiftCardPriceAndStock() {
        const {
            product,
            product: { variants },
            product: {
                price_range: {
                    minimum_price: {
                        final_price: {
                            currency
                        }
                    }
                }
            },
            configurableVariantIndex,
            showOnlyIfLoaded,
            selectedValue
        } = this.props;

        return (
            <GiftCardPriceAndStock
              product={ product }
              currency={ currency }
              configurableVariantIndex={ configurableVariantIndex }
              variants={ variants }
              showOnlyIfLoaded={ showOnlyIfLoaded }
              selectedPrice={ selectedValue }
            />
        );
    }

    render() {
        return (
            <div block="GiftCardActions">
                <div block="GiftCardActions" elem="CardValue">
                    { this.renderGiftCardPriceAndStock() }
                    { this.renderGiftCardPrice() }
                </div>
            </div>
        );
    }
}

export default GiftCardActionsComponent;
