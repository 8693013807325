/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Plisko <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

export const GIFT_CARD_OPTION_LABELS = {
    am_giftcard_amount: __('Card Value'),
    am_giftcard_sender_name: __('Sender Name'),
    am_giftcard_recipient_name: __('Recipient Name'),
    am_giftcard_recipient_email: __('Recipient Email'),
    am_giftcard_date_delivery: __('Delivery Date'),
    am_giftcard_date_delivery_timezone: __('Delivery Timezone'),
    am_giftcard_message: __('Message')
};

export class CartItemPlugin {
    renderGiftCardOptions(gift_card_options) {
        if (!gift_card_options) {
            return null;
        }

        return (
            <div block="CartItem" elem="CustomizableOptionsWrapper">
                { Object.entries(gift_card_options).filter((value) => !!value[1]).map(([key, value]) => (
                    <div
                      block="CartItem"
                      elem="CustomizableOption"
                      key={ key }
                    >
                        <div
                          block="CartItem"
                          elem="CustomizableOptionLabel"
                          key={ `label-${ key }` }
                        >
                            { `${ GIFT_CARD_OPTION_LABELS[key] }:` }
                        </div>
                        <div block="CartItem" elem="CustomizableOptionValues">
                            <div
                              block="CartItem"
                              elem="CustomizableOptionValue"
                              key={ value }
                            >
                                { value }
                            </div>
                        </div>
                    </div>
                )) }
            </div>
        );
    }

    renderActions = (args, callback, instance) => {
        const {
            isLikeTable,
            isEditing,
            item: {
                product: {
                    type_id
                }
            }
        } = instance.props;

        if (type_id !== 'amgiftcard') {
            return callback(...args);
        }

        return (
            <div
              block="CartItem"
              elem="Actions"
              mods={ { isEditing, isLikeTable } }
            >
                { instance.renderDeleteButton() }
            </div>
        );
    };

    renderContent = (args, callback, instance) => {
        const {
            isLikeTable,
            item: {
                bundle_options,
                downloadable_links,
                customizable_options,
                gift_card_options,
                product: {
                    type_id
                }
            }
        } = instance.props;

        if (type_id !== 'amgiftcard') {
            return callback(...args);
        }

        return (
            <figcaption
              block="CartItem"
              elem="Content"
              mods={ { isLikeTable } }
            >
                { instance.renderOutOfStockMessage() }
                <div block="CartItem" elem="HeadingWrapper">
                    { instance.renderProductName() }
                    { this.renderGiftCardOptions(gift_card_options) }
                    { instance.renderDeleteButton(true) }
                </div>
                { instance.renderProductOptions(customizable_options) }
                { instance.renderProductOptions(bundle_options) }
                { instance.renderProductLinks(downloadable_links) }
                { instance.renderProductConfigurations() }
                { instance.renderProductPrice() }
            </figcaption>
        );
    };
}

export const {
    renderContent,
    renderActions
} = new CartItemPlugin();

export default {
    'Component/CartItem/Component': {
        'member-function': {
            renderContent: [
                {
                    position: 100,
                    implementation: renderContent
                }
            ],
            renderActions: [
                {
                    position: 80,
                    implementation: renderActions
                }
            ]
        }
    }
};
