/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_PRODUCT_CLICK } from '../../../util/Event';
import { EVENT_IMPRESSION } from '../GoogleTagManager.config';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';
import { SPAM_PROTECTION_DELAY } from './Impression.event';

/**
 * Product click event
 * @namespace Gtm/Component/GoogleTagManager/Events/ProductClick/Event/ProductClickEvent
 */
export class ProductClickEvent extends BaseEvent {
    /**
     * Set delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Bind click events
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PRODUCT_CLICK, (product) => {
            this.handle(product);
        });
    }

    /**
     * Handle product click
     */
    handler(product) {
        const { impressions = [] } = this.getStorage(EVENT_IMPRESSION);
        const impressionUID = impressions.reduce((acc, { id }) => `${ acc }_${ id }`, '');

        if (!this.getProductFromImpression(product)
            || Object.values(this.getProductFromImpression(product)).length === 0
            || this.spamProtection(SPAM_PROTECTION_DELAY, `${ window.currentRouteName }_${ impressionUID }`)
        ) {
            return;
        }

        const {
            position = 1,
            list = ''
        } = this.getProductFromImpression(product) || {};

        if (list === 'Wishlist') {
            this.pushEventData({
                ecommerce: {
                    click: {
                        actionField: {
                            list
                        },
                        products: [
                            {
                                ...ProductHelper.getProductDataFromWishlist(this.getProductFromImpression(product))
                            }
                        ]
                    }
                }
            });
        } else {
            this.pushEventData({
                ecommerce: {
                    click: {
                        actionField: {
                            list
                        },
                        products: [
                            {
                                ...ProductHelper.getProductData(product),
                                position
                            }
                        ]
                    }
                }
            });
        }
    }

    /**
     * Get product position in impression
     *
     * @return {*}
     * @param clickedProduct
     */
    getProductFromImpression(clickedProduct) {
        const { impressions = [] } = this.getStorage(EVENT_IMPRESSION);
        const id = ProductHelper.getSku(clickedProduct);
        const { sku } = clickedProduct;

        return impressions.find(({ id: impressionId }) => (
            id.includes(impressionId) || sku.includes(impressionId)
        ));
    }
}

export default ProductClickEvent;
