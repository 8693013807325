/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import Form from 'Component/Form';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';

import CheckoutGiftCardInput from '../component/CheckoutGiftCardInput';

export class CheckoutBillingPlugin {
    render = (args, callback, instance) => {
        const { onBillingSuccess, onBillingError } = instance.props;

        return (
            <Form
              mix={ { block: 'CheckoutBilling' } }
              id={ BILLING_STEP }
              onSubmitError={ onBillingError }
              onSubmitSuccess={ onBillingSuccess }
            >
                { instance.renderAddresses() }
                { instance.renderPayments() }
                { instance.renderTermsAndConditions() }
                { instance.renderActions() }
                <CheckoutGiftCardInput />
                { instance.renderPopup() }
            </Form>
        );
    };
}

export const { render } = new CheckoutBillingPlugin();

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            render: [
                {
                    position: 100,
                    implementation: render
                }
            ]
        }
    }
};
