/* eslint-disable no-restricted-globals */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';

import { EVENT_GENERAL } from '../GoogleTagManager.config';
import { GoogleTagManagerContainer } from '../GoogleTagManager.container';

export const PRODUCT_SIZE = 'variant';
export const GROUPED_PRODUCT_PRICE = 'metric1';
export const PRODUCT_VARIANT_NAME = 'dimension1';
export const PRODUCT_VARIANT_SKU = 'dimension2';
export const PRODUCT_DEPARTMENT = 'dimension3';
export const PRODUCT_TYPE = 'dimension4';
export const PRODUCT_COLOR = 'dimension5';

export const NOT_APPLICABLE = 'N/A';
export const MAX_CATEGORIES_NUMBER = 4;

/**
 * Product helper, contain all related to product data prepare methods
 * @namespace Gtm/Component/GoogleTagManager/Utils/Product/Product
 */
export class Product {
    static DEFAULT_BRAND = 'HairyBaby';

    /**
     * Get product listing category string
     *
     * @param product
     * @return {string|string}
     */
    // eslint-disable-next-line no-unused-vars
    static getList() {
        const meta = GoogleTagManagerContainer.getEvent(EVENT_GENERAL).currentMeta.metaObject || {};

        return meta.name
            || meta.title
            || document.title.split('|').pop()
            || '';
    }

    /**
     * Get product Quantity from product object
     *
     * @param product
     * @return {number|string}
     */
    static getQuantity({ qty }) {
        return parseInt(qty, 10) || 0;
    }

    /**
     * Get product brand from product object
     *
     * @return {string|string}
     * @param selectedVariant
     */
    static getBrand(selectedVariant) {
        const { attributes = {} } = selectedVariant;
        const { brand: { attribute_value = '' } = {} } = attributes;

        return attribute_value;
    }

    static getSelectedVariant(product) {
        const { sku, variants = [] } = product;

        return variants.find(({ sku: variantSku }) => sku === variantSku);
    }

    static getSelectedVariantIndex(product, sku) {
        const { variants = [] } = product;

        return variants.findIndex(({ sku: variantSku = '' }) => sku === variantSku);
    }

    /**
     * Get product sku
     *
     * @param product
     * @return {string}
     */
    static getSku(product) {
        const { variants = [], configurableVariantIndex = -1 } = product;
        const { sku = '' } = variants[configurableVariantIndex] || product;

        return sku;
    }

    /**
     * Get item data as object
     *
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string}}
     * @param isVariantPassed
     * @param item
     */
    static getItemData(item, isVariantPassed = false) {
        if (item && Object.values(item).length) {
            const { product = {}, sku = '' } = item;
            const configurableVariantIndex = this.getSelectedVariantIndex(product, sku);

            return this.getProductData({ ...product, configurableVariantIndex }, isVariantPassed);
        }

        return {};
    }

    static getCategory(categories = []) {
        return categories.slice(0, MAX_CATEGORIES_NUMBER).map(({ name }) => (name)).join('/');
    }

    static getPrice(variant) {
        const {
            price_range: {
                minimum_price: {
                    discount: {
                        amount_off = 0
                    } = {},
                    regular_price: {
                        value = 0
                    } = {}
                } = {}
            } = {}
        } = variant;

        return +roundPrice(value - amount_off);
    }

    /**
     * @param groupedProductData
     * @param product
     * @param groupedProductPrice
     */
    static addGroupedProduct(groupedProductData, product, groupedProductPrice) {
        const GTMInstance = GoogleTagManagerContainer.getInstance();
        const groupedProducts = GTMInstance.getGroupedProducts();
        const { sku, items } = product;
        const existingGroupedProduct = groupedProducts[sku];

        if (existingGroupedProduct) {
            const { data: { [GROUPED_PRODUCT_PRICE]: oldPrice } } = existingGroupedProduct;
            groupedProducts[sku].data[GROUPED_PRODUCT_PRICE] = groupedProductPrice + oldPrice;
        } else {
            groupedProducts[sku] = {
                data: groupedProductData,
                items: this.getArrayOfGroupedProductChildrenSku(items)
            };
        }

        GTMInstance.setGroupedProducts(groupedProducts);
    }

    static getArrayOfGroupedProductChildrenSku(items) {
        return items.reduce((acc, { product: { sku } }) => [...acc, sku], []);
    }

    static updateGroupedProduct(childSku, price) {
        const GTMInstance = GoogleTagManagerContainer.getInstance();
        const groupedProducts = GTMInstance.getGroupedProducts();
        const skuOfProductToUpdate = Object.keys(groupedProducts).find((sku) => {
            const { items } = groupedProducts[sku];

            return items.includes(childSku);
        });

        if (skuOfProductToUpdate) {
            const { [GROUPED_PRODUCT_PRICE]: prevPrice } = groupedProducts[skuOfProductToUpdate].data;

            // 0 price metric form grouped product indicates that no more children products are left in cart
            if (prevPrice + price === 0) {
                const productToDelete = groupedProducts[skuOfProductToUpdate];
                // eslint-disable-next-line fp/no-delete
                delete groupedProducts[skuOfProductToUpdate];

                GTMInstance.setGroupedProducts(groupedProducts);

                return productToDelete;
            }

            groupedProducts[skuOfProductToUpdate].data[GROUPED_PRODUCT_PRICE] += price;
            GTMInstance.setGroupedProducts(groupedProducts);
        }

        return null;
    }

    static mergeGroupedProducts(groupedProducts1, groupedProducts2) {
        if (!groupedProducts1) {
            return groupedProducts2;
        }
        if (!groupedProducts2) {
            return groupedProducts1;
        }

        const result = { ...groupedProducts2 };

        Object.keys(groupedProducts1).forEach((key) => {
            if (groupedProducts2[key]) {
                result[key].data[GROUPED_PRODUCT_PRICE] += groupedProducts1[key].data[GROUPED_PRODUCT_PRICE];
            } else {
                result[key] = groupedProducts1[key];
            }
        });

        return result;
    }

    /**
     * varian: color
     * dimension1: size
     * dimension2: simple/grouped
     * dimension3: variantSKU
     * metric1: total for grouped product
     */
    static getVariantNameOrSku(nameSku, variantNameSku, isVariantPassed, isDimensionNecessary, type_id, variants, variableName) {
        if (type_id === 'configurable' && isVariantPassed === false && isDimensionNecessary === true) {
            if (variants.length > 0) {
                return variants[0][variableName];
            }

            return NOT_APPLICABLE;
        }

        return (variantNameSku === nameSku && !isVariantPassed)
            ? NOT_APPLICABLE
            : variantNameSku;
    }

    static getGroupedProductPrice(product) {
        const { groupedProductPrice = 0 } = product;
        return groupedProductPrice;
    }

    static getAttribute(variant, parentAttributes, attributeName, isVariantPassed) {
        if (isVariantPassed) {
            const { attribute_value = '' } = variant.attributes[attributeName] || {};
            const { attribute_options = {} } = parentAttributes[attributeName] || {};
            const { label = NOT_APPLICABLE } = attribute_options[attribute_value] || {};

            return label || NOT_APPLICABLE;
        }

        const { attribute_options = {} } = parentAttributes[attributeName] || {};
        const label = Object.values(attribute_options).map(({ label }) => label).join('/') || NOT_APPLICABLE;

        return label || NOT_APPLICABLE;
    }

    static getProductDataFromWishlist(product) {
        const {
            name,
            id,
            price,
            brand,
            category,
            position = 1,
            variant,
            dimension3,
            dimension4,
            dimension5
        } = product || {};

        return {
            name,
            id,
            price,
            brand,
            category,
            position,
            variant,
            dimension1: 'N/A',
            dimension2: 'N/A',
            dimension3,
            dimension4,
            dimension5
        };
    }

    /**
     * Get product data as object
     *
     * @param product
     *
     * @param isVariantPassed
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string}}
     */
    static getProductData(product, isVariantPassed = false, isDimensionNecessary = false) {
        const {
            sku,
            name,
            type_id,
            category = NOT_APPLICABLE,
            variants = [],
            categories = [],
            attributes = {},
            configurableVariantIndex = this.getSelectedVariantIndex(product, sku)
        } = product;
        const selectedVariant = variants[configurableVariantIndex] || product;
        const { sku: variantSku } = selectedVariant;
        const { name: variantName } = selectedVariant;

        return {
            id: sku,
            name,
            price: this.getPrice(selectedVariant, type_id),
            brand: this.getBrand(selectedVariant) || this.DEFAULT_BRAND,
            category: this.getCategory(categories) || category,
            [PRODUCT_SIZE]: this.getAttribute(selectedVariant, attributes, 'choose_size', isVariantPassed),
            [PRODUCT_VARIANT_NAME]: this.getVariantNameOrSku(name, variantName, isVariantPassed, isDimensionNecessary, type_id, variants, 'name'),
            [PRODUCT_VARIANT_SKU]: this.getVariantNameOrSku(sku, variantSku, isVariantPassed, isDimensionNecessary, type_id, variants, 'sku'),
            [PRODUCT_DEPARTMENT]: this.getAttribute(selectedVariant, attributes, 'choose_style', isVariantPassed),
            [PRODUCT_TYPE]: this.getAttribute(selectedVariant, attributes, 'type', isVariantPassed),
            [PRODUCT_COLOR]: this.getAttribute(selectedVariant, attributes, 'choose_colour', isVariantPassed)
        };
    }
}

export default Product;
