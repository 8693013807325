/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import CardDesignPreview from './CardDesignPreview.component';

/** @namespace Giftcard/Component/CardDesignPreview/Container/CardDesignPreviewContainer */
export class CardDesignPreviewContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        giftCardDesign: PropTypes.string.isRequired,
        registerSharedElementDestination: PropTypes.func.isRequired
    };

    imageRef = createRef();

    componentDidMount() {
        this.updateSharedDestinationElement();
    }

    containerProps = () => {
        const {
            product,
            giftCardDesign
        } = this.props;

        return {
            product,
            giftCardDesign,
            imageRef: this.imageRef
        };
    };

    updateSharedDestinationElement() {
        const { registerSharedElementDestination } = this.props;

        registerSharedElementDestination(this.imageRef);
    }

    render() {
        return (
            <CardDesignPreview
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default CardDesignPreviewContainer;
