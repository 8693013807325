/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Manuel Trinidad <info@scandiweb.com>
 * @author  Mikheili Papiashvili <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Html from 'Component/Html';
import Loader from 'Component/Loader';

import { CardType } from '../../type/GiftCard';

/** @namespace Giftcard/Component/CheckoutGiftCardInput/Component/CheckoutGiftCardInputComponent */
export class CheckoutGiftCardInputComponent extends PureComponent {
    static propTypes = {
        handleGiftCardRemove: PropTypes.func.isRequired,
        handleGiftCardSubmit: PropTypes.func.isRequired,
        handleGiftCardCheck: PropTypes.func.isRequired,
        checkedCode: PropTypes.objectOf(PropTypes.string),
        giftCards: PropTypes.arrayOf(CardType).isRequired,
        value: PropTypes.string.isRequired,
        handleGiftCardCodeChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        checkedCode: {}
    };

    state = {
        isLoading: false
    };

    renderTable(card) {
        const {
            code, status, balance, expiredDate
        } = card;

        return (
            <div block="KeyValueTable" elem="Wrapper">
                <table>
                    <thead>
                        <tr block="KeyValueTable" elem="Heading">
                            <th>{ code }</th>
                            { this.renderGiftCardRemoveButton(code) }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{ __('Code') }</th>
                            <td>{ code }</td>
                        </tr>
                        <tr>
                            <th>{ __('Status') }</th>
                            <td>{ status }</td>
                        </tr>
                        <tr>
                            <th>{ __('Balance') }</th>
                            <td>
                                <Html content={ balance } />
                            </td>
                        </tr>
                        <tr>
                            <th>{ __('Expiration Date') }</th>
                            <td>{ expiredDate }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderGiftCardRemoveButton(code) {
        const { handleGiftCardRemove } = this.props;

        return (
            <div block="GiftCard" elem="CardColumn">
                <button onClick={ (e) => handleGiftCardRemove(e, code) }>
                    { __('Remove') }
                </button>
            </div>
        );
    }

    renderCheckedGiftCard() {
        const {
            checkedCode: {
                balance,
                code,
                expiredDate,
                status
            } = {},
            checkedCode
        } = this.props;

        if (!Object.values(checkedCode).length) {
            return null;
        }

        return (
            <div block="CheckoutGiftCardInput" elem="GiftCardRow">
                <div block="CheckoutGiftCardInput" elem="GiftCardColumn">
                    <p block="CheckoutGiftCardInput" elem="Title">{ __('Code:') }</p>
                    <p block="CheckoutGiftCardInput" elem="Value">{ code }</p>
                </div>
                <div block="CheckoutGiftCardInput" elem="GiftCardColumn">
                    <p block="CheckoutGiftCardInput" elem="Title">{ __('Status:') }</p>
                    <p block="CheckoutGiftCardInput" elem="Value">{ status }</p>
                </div>
                <div block="CheckoutGiftCardInput" elem="GiftCardColumn">
                    <p block="CheckoutGiftCardInput" elem="Title">{ __('Current Balance:') }</p>
                    <p block="CheckoutGiftCardInput" elem="Value">
                        <Html content={ balance } />
                    </p>
                </div>
                <div block="CheckoutGiftCardInput" elem="GiftCardColumn">
                    <p block="CheckoutGiftCardInput" elem="Title">{ __('Valid till:') }</p>
                    <p block="CheckoutGiftCardInput" elem="Value">{ expiredDate }</p>
                </div>
            </div>
        );
    }

    renderEnabledGiftCodes() {
        const { giftCards } = this.props;

        if (!giftCards.length) {
            return null;
        }

        return (
            <div block="CheckoutGiftCard" elem="Wrapper">
                { giftCards.map((card) => this.renderTable(card)) }
            </div>
        );
    }

    render() {
        const {
            handleGiftCardSubmit,
            handleGiftCardCheck,
            handleGiftCardCodeChange,
            value
        } = this.props;
        const { isLoading } = this.state;

        return (
            <div block="CheckoutGiftCardInput">
                { this.renderEnabledGiftCodes() }
                <p block="CheckoutGiftCardInput" elem="Header">
                    <b>{ __('Have a gift voucher?') }</b> &nbsp; { __('(for coupon codes please scroll down)') }
                </p>
                <Loader isLoading={ isLoading } />
                <Field
                  block="CheckoutGiftCardInput"
                  elem="Input"
                  onChange={ handleGiftCardCodeChange }
                  placeholder={ __('Gift Voucher Code') }
                  type="text"
                  value={ value }
                />
                <button
                  block="CheckoutGiftCardInput"
                  elem="AddButton"
                  mix={ { block: 'Button' } }
                  onClick={ () => handleGiftCardSubmit(value) }
                  disabled={ !value }
                  type="button"
                >
                    { __('Redeem') }
                </button>
                <button
                  block="CheckoutGiftCardInput"
                  elem="CheckButton"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ () => handleGiftCardCheck(value) }
                  disabled={ !value }
                  type="button"
                >
                    { __('Check') }
                </button>
                { this.renderCheckedGiftCard() }
            </div>
        );
    }
}

export default CheckoutGiftCardInputComponent;
