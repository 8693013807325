/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from 'SourceComponent/WidgetFactory/WidgetFactory.config';

export const CATALOG_FEATURED_PRODUCT_LIST = 'CatalogFeaturedProductList';
export const CATALOG_COLLABORATIONS_PRODUCT_LIST = 'CatalogCollaborationsProductList';
