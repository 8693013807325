/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import { Field, Fragment } from 'Util/Query';

/** @namespace Scandiweb/GiftCardScandiPWA/Plugin/Plugin */
export class ProductListQueryPlugin {
    _getListTimezones() {
        return new Field('getListTimezones');
    }

    _getPrice() {
        return new Field('value')
            .addField(new Field('value'))
            .addField(new Field('currency'));
    }

    _getGiftCardPriceFields() {
        return [
            'price_id',
            'website_id',
            'attribute_id',
            this._getPrice()
        ];
    }

    _getAmGiftCardPrices() {
        return new Field('am_giftcard_prices')
            .addFieldList(this._getGiftCardPriceFields());
    }

    _getAmGiftCardProductFragmentFields() {
        return [
            this._getAmGiftCardPrices(),
            'am_giftcard_fee_enable',
            'am_giftcard_fee_type',
            'am_giftcard_fee_value',
            'am_giftcard_type',
            'am_giftcard_lifetime',
            'am_email_template'
        ];
    }

    _getAmGiftCardFragment() {
        return new Fragment('AmGiftCardProduct')
            .addFieldList(this._getAmGiftCardProductFragmentFields());
    }

    _getProductInterfaceFields = (args, callback, instance) => {
        const { isSingleProduct = false } = instance.options;
        const fields = callback.apply(instance, args);

        if (!args[0] && isSingleProduct) {
            fields.push(this._getListTimezones());
            fields.push(this._getAmGiftCardFragment());
        }

        return fields;
    };
}

const { _getProductInterfaceFields } = new ProductListQueryPlugin();

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: [
                {
                    position: 10,
                    implementation: _getProductInterfaceFields
                }
            ]
        }
    }
};
