/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Manuel Trinidad <info@scandiweb.com>
 * @author  Gowtham Satti <info@scandiweb.com>
 */

// eslint-disable-next-line max-classes-per-file

import MyAccountGiftCardInput from '../component/MyAccountGiftCardInput';

export const GIFT_CARDS = 'gift-cards';
export const GIFT_CARDS_URL = '/gift-cards';

const renderMap = (originalMember) => ({
    [GIFT_CARDS]: MyAccountGiftCardInput,
    ...originalMember
});

const tabMap = (originalMember) => ({
    ...originalMember,
    [GIFT_CARDS]: {
        url: GIFT_CARDS_URL,
        name: __('Gift Cards')
    }
});

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': { renderMap }
    }
};
