/* eslint-disable jsx-a11y/alt-text */
import CmsBlock from 'Component/CmsBlock';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import {
    HAIRY_BABY_COPYRIGHT,
    PAY_METHODS
} from './Footer.config';

import './Footer.override.style';

/** @namespace Scandipwa/Component/Footer/Component/FooterComponent */
export class FooterComponent extends SourceFooter {
    renderColumnNewsletterSubscription() {
        return (
            <div block="Footer" elem="Column" mods={ { type: 'isNewsLetter' } }>
                <h3 block="Footer" elem="isNewsLetterTitle">
                    { __('Sign up for our newsletter & get the new stuff first!') }
                </h3>
                <NewsletterSubscription />
                <div block="Footer" elem="PayMethods">
                    { PAY_METHODS.map((item) => (
                        <img
                          mix={ { block: 'Footer', elem: 'PayMethodIcon' } }
                          src={ item.src }
                          alt={ item.title }
                          key={ item.title }
                          height={ item.height }
                        />
                    )) }
                </div>
            </div>
        );
    }

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                    { this.renderColumnNewsletterSubscription() }
                </div>
            </div>
        );
    }

    renderCopyrightContent() {
        return (
            <CmsBlock identifier={ HAIRY_BABY_COPYRIGHT } />
        );
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderCmsBlockWrapper() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }
}

export default FooterComponent;
