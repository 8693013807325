/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/scandipwa-gtm
 * @author  Ainars Sondors <info@scandiweb.com>
 */
import Event, {
    EVENT_GTM_NOT_FOUND
} from '../../util/Event';

export const afterComponentDidMount = (args, callback, _instance) => {
    callback(...args);

    Event.dispatch(EVENT_GTM_NOT_FOUND);
};

export default {
    'Route/NoMatch/Container': {
        'member-function': {
            componentDidMount: afterComponentDidMount
        }
    }
};
