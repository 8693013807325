/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import './CardDesignPreview.style';

/** @namespace Giftcard/Component/CardDesignPreview/Component/CardDesignPreviewComponent */
export class CardDesignPreviewComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        giftCardDesign: PropTypes.string.isRequired,
        imageRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired
    };

    renderImage() {
        const {
            giftCardDesign,
            product: { name = '' } = {},
            imageRef
        } = this.props;

        return (
            <img
              ref={ imageRef }
              src={ giftCardDesign }
              alt={ name }
              block="CardDesignPreview"
              elem="Image"
            />
        );
    }

    render() {
        return (
            <div block="CardDesignPreview" elem="Wrapper">
                { this.renderImage() }
            </div>
        );
    }
}

export default CardDesignPreviewComponent;
