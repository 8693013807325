/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/scandipwa-gtm
 * @author  Ainars Sondors <info@scandiweb.com>
 */

import Event, { EVENT_GTM_NOT_FOUND } from '../../../util/Event';
import BaseEvent from './BaseEvent.event';

export const NOT_FOUND_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 * @namespace Gtm/Component/GoogleTagManager/Events/NotFoundPage/Event/NotFoundPageEvent */
export class NotFoundPageEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = NOT_FOUND_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_NOT_FOUND, () => {
            this.handle();
        });
    }

    checkDataExists() {
        return true;
    }

    /**
     * Handle
     */
    handler() {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({});
    }
}

export default NotFoundPageEvent;
