/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';

import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    CATALOG_COLLABORATIONS_PRODUCT_LIST,
    CATALOG_FEATURED_PRODUCT_LIST
} from './WidgetFactory.config';

export const FeaturedProductListWidget = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/FeaturedProductListWidget')
);

export const CollaborationsProductListWidget = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/CollaborationsProductListWidget')
);

/** @namespace Scandipwa/Component/WidgetFactory/Component/WidgetFactoryComponent */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        [CATALOG_COLLABORATIONS_PRODUCT_LIST]: {
            component: CollaborationsProductListWidget
        },
        [CATALOG_FEATURED_PRODUCT_LIST]: {
            component: FeaturedProductListWidget
        }
    };
}

export default WidgetFactoryComponent;
