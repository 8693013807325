/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 * @author Anastasija Zubkova <info@scandiweb.com>
 */

import CheckoutPayments from 'Component/CheckoutPayments';

/** @namespace BraintreeGraphql/Plugin/CheckoutBilling/aroundRenderPayments */
export const renderPayments = (args, callback, instance) => {
    const {
        isSavePayment,
        onPaymentSavedInVaultChange,
        paymentMethods,
        onPaymentMethodSelect,
        setLoading,
        setDetailsStep,
        shippingAddress
    } = instance.props;

    const { isTermsAndConditionsAccepted } = instance.state;

    if (!paymentMethods.length) {
        return null;
    }

    return (
        <CheckoutPayments
          setLoading={ setLoading }
          setDetailsStep={ setDetailsStep }
          paymentMethods={ paymentMethods }
          onPaymentMethodSelect={ onPaymentMethodSelect }
          setOrderButtonVisibility={ instance.setOrderButtonVisibility }
          billingAddress={ shippingAddress }
          setOrderButtonEnableStatus={ instance.setOrderButtonEnableStatus }
          isSavePayment={ isSavePayment }
          onPaymentSavedInVaultChange={ onPaymentSavedInVaultChange }
          isTermsAndConditionsAccepted={ isTermsAndConditionsAccepted }
        />
    );
};

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments
        }
    }
};
