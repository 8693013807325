import { lazy } from 'react';

export const ProductLabel = lazy(
    () => import(
        '../component/ProductLabel'
    )
);

export class ProductCardPlugin {
     aroundRenderPicture = (args, callback, instance) => {
         const { product: { mp_label_data = [] } } = instance.props;

         return (
             <>
                 { callback.apply(instance, args) }
                 <ProductLabel labelData={ mp_label_data } />
             </>
         );
     };
}

const { aroundRenderPicture } = new ProductCardPlugin();

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderPicture: {
                position: 100,
                implementation: aroundRenderPicture
            }
        }
    }
};
