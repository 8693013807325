import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { isCrawler, isSSR } from 'Util/Browser';

import { HEADER_CMS_BLOCK } from './Menu.config';

import './Menu.override.style';

/** @namespace Scandipwa/Component/Menu/Component/MenuComponent */
export class MenuComponent extends SourceMenu {
    static propTypes = {
        ...this.propTypes,
        isCheckout: PropTypes.bool.isRequired
    };

    renderAdditionalInformation(checkMobile = false) {
        const { device: { isMobile } } = this.props;

        if (checkMobile && !isMobile) {
            return null;
        }

        return (
            <>
                { this.renderPromotionCms() }
            </>
        );
    }

    renderPromotionCms() {
        const { closeMenu } = this.props;
        const { header_content: { header_cms } = {} } = window.contentConfiguration;

        if (header_cms) {
            return <CmsBlock identifier={ header_cms } blockType={ HEADER_CMS_BLOCK } />;
        }

        return (
            <div block="Menu" elem="Promotion">
                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/about-us"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        { __('About us') }
                    </Link>
                </h3>
                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/about-us"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        { __('Contacts') }
                    </Link>
                </h3>
            </div>
        );
    }

    renderTopLevel() {
        const { isCheckout } = this.props;

        if (isCheckout) {
            return null;
        }

        return super.renderTopLevel();
    }

    render() {
        const { closeMenu } = this.props;

        if ((isCrawler() || isSSR())) {
            return (
                <div
                  block="Menu"
                  elem="MenuWrapper"
                  mix={ { block: 'Menu', elem: 'MenuWrapperPre' } }
                  onMouseLeave={ closeMenu }
                >
                    { this.renderTopLevel() }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >
                { this.renderTopLevel() }
            </div>
        );
    }
}

export default MenuComponent;
