/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import { IS_NON_REFUNDABLE_ATTR_CODE } from '@scandipwa/pdp-agreement/src/plugin/ProductActions.plugin';
import PropTypes from 'prop-types';
import React from 'react';

import AddToCart from 'Component/AddToCart';

import CardDesignSelection from '../component/CardDesignSelection';
import GiftCardActions from '../component/GiftCardActions';
import GiftCardForm from '../component/GiftCardForm';
import ScheduleDelivery from '../component/ScheduleDelivery';

/** @namespace Scandiweb/GiftCardScandiPWA/Plugin/Plugin */
export class ProductActionPlugin {
    aroundMapStateToProps = (args, callback) => {
        const {
            0: {
                ConfigReducer: {
                    header_logo_src
                }
            }
        } = args;

        return {
            ...callback(...args),
            header_logo_src
        };
    };

    propTypes = (originalMember) => ({
        ...originalMember,
        header_logo_src: PropTypes.string.isRequired
    });

    render = (args, callback, instance) => {
        const {
            product: { type_id } = {}
        } = instance.props;

        if (type_id !== 'amgiftcard') {
            return callback(...args);
        }

        return (
            <article block="ProductActions">
                { instance.renderShortDescription() }
                { instance.renderDownloadableProductSample() }
                { instance.renderDownloadableProductLinks() }
                { instance.renderAddToCartWrapper() }
                { instance.renderReviews() }
                { instance.renderNameAndBrand() }
                { this.renderGiftCardActions(instance) }
                { this.renderCardDesignSelection(instance) }
                { this.renderGiftCardForm(instance) }
                { this.renderScheduleDelivery(instance) }
            </article>
        );
    };

    renderAddToCartWrapper = (args, callback, instance) => {
        const {
            product: {
                type_id,
                gift_card_options
            } = {}
        } = instance.props;

        if (type_id === 'amgiftcard') {
            return (
                <div
                  block="ProductActions"
                  elem="AddToCartWrapper"
                  mix={ { block: 'FixedElement', elem: 'Bottom' } }
                >
                    { this.renderAddToCart(instance, gift_card_options) }
                    { instance.renderProductWishlistButton() }
                </div>
            );
        }

        return callback(...args);
    };

    renderAddToCart(instance, giftCardData) {
        const {
            configurableVariantIndex,
            product,
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            product: {
                attributes = {}
            },
            isTermsAndConditionsEnabled,
            getIsOutOfStock
        } = instance.props;

        const { [IS_NON_REFUNDABLE_ATTR_CODE]: { attribute_value = '' } = {} } = attributes;
        const isProductNonRefundable = !!Number(attribute_value);

        const {
            isTermsAndConditionsAccepted
            = (!isProductNonRefundable || !isTermsAndConditionsEnabled)
        } = instance.state || {};

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
              giftCardData={ giftCardData }
              isTermsAndConditionsAccepted={ isTermsAndConditionsAccepted }
              getIsOutOfStock={ getIsOutOfStock }
            />
        );
    }

    renderGiftCardActions(instance) {
        return (
            <GiftCardActions
              { ...instance.props }
            />
        );
    }

    renderCardDesignSelection(instance) {
        return (
            <CardDesignSelection
              { ...instance.props }
            />
        );
    }

    renderGiftCardForm(instance) {
        return (
            <GiftCardForm
              { ...instance.props }
            />
        );
    }

    renderScheduleDelivery(instance) {
        const {
            updateGiftCardOptions,
            product: {
                getListTimezones
            }
        } = instance.props;

        return (
            <ScheduleDelivery
              getListTimezones={ getListTimezones }
              updateGiftCardOptions={ updateGiftCardOptions }
            />
        );
    }
}

const {
    render,
    renderAddToCartWrapper,
    aroundMapStateToProps,
    propTypes
} = new ProductActionPlugin();

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            render: [
                {
                    position: 150,
                    implementation: render
                }
            ],
            renderAddToCartWrapper: [
                {
                    position: 100,
                    implementation: renderAddToCartWrapper
                }
            ]
        },
        'static-member': {
            propTypes
        }
    },
    'Component/ProductActions/Container/mapStateToProps': {
        function: aroundMapStateToProps
    }
};
