/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { isCrawler, isSSR } from 'Util/Browser';
import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import GdprCookieQuery from '../../query/GdprCookie.query';
import GdprCookiePopup from './GdprCookiePopup.component';

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    isCookieBarActive: state?.ConfigReducer?.gdpr_cookie_bar
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/GdprCookiePopupContainer */
export class GdprCookiePopupContainer extends DataContainer {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired
    };

    state = {
        isLoading: true,
        isStatic: true,
        checkedGroups: [],
        isCookieSet: !!this.handleGetCookie('amcookie_allowed'),
        cookieSettings: null
    };

    containerFunctions = {
        handleCheckedGroups: this.handleCheckedGroups.bind(this),
        handleAcceptAllGroups: this.handleAcceptAllGroups.bind(this),
        handleDeclineCookies: this.handleDeclineCookies.bind(this),
        handleCloseCookies: this.handleCloseCookies.bind(this),
        handleAcceptCookies: this.handleAcceptCookies.bind(this)
    };

    componentDidMount() {
        const {
            isCookieSet
        } = this.state;

        if (!isCookieSet) {
            this._getCookieSettings();
        }
    }

    componentDidUpdate() {
        const { isCookieBarActive } = this.props;
        const {
            isLoading,
            isCookieSet
        } = this.state;

        if (isLoading && isCookieBarActive && !isCookieSet) {
            this._getCookieGroups();
            document.documentElement.classList.add('scrollDisabled');
        }
    }

    handleCheckedGroups(groupId, isChecked) {
        const { checkedGroups } = this.state;

        if (isChecked) {
            this.setState({ checkedGroups: [...checkedGroups, groupId] });
        } else {
            this.setState({ checkedGroups: checkedGroups.filter((checkedGroupId) => checkedGroupId !== groupId) });
        }
    }

    async handleAcceptCookies() {
        const { hideActiveOverlay, showNotification, activeOverlay } = this.props;
        const { checkedGroups } = this.state;

        if (activeOverlay !== 'gdpr_cookie_group') {
            const request = await fetchMutation(GdprCookieQuery.setSelectedCookies(checkedGroups));

            const { setSelectedCookies: { message, result } } = request;

            if (result) {
                showNotification('success', message);
                this.setState({ isStatic: false });
                hideActiveOverlay();
            }
        }
    }

    async handleAcceptAllGroups() {
        const { hideActiveOverlay, showNotification } = this.props;

        const request = await fetchMutation(GdprCookieQuery.setAllCookies());

        const { setAllCookies: { message, result } } = request;

        if (result) {
            showNotification('success', message);
            this.setState({ isStatic: false });
            hideActiveOverlay();
        }
    }

    async handleDeclineCookies() {
        const { hideActiveOverlay } = this.props;
        const { cookieGroups } = this.state;

        const essentialCookies = cookieGroups.reduce((acc, cookieGroup) => {
            const { isEssential, groupId } = cookieGroup;

            if (!isEssential) {
                return acc;
            }

            return [...acc, groupId];
        }, []);

        await fetchMutation(GdprCookieQuery.setSelectedCookies(essentialCookies));

        this.setState({ isStatic: false });
        hideActiveOverlay();
    }

    handleCloseCookies() {
        const { hideActiveOverlay } = this.props;

        this.setState({ isStatic: false });
        hideActiveOverlay();
    }

    handleGetCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }

        return '';
    }

    _getCookieGroups() {
        this.fetchData(
            [GdprCookieQuery.getCookieGroups()],
            ({ cookieGroups }) => {
                this.setState({ isLoading: false });
                this.setState({ cookieGroups });
            }
        );
    }

    _getCookieSettings() {
        this.fetchData(
            [GdprCookieQuery.getCookieSettings()],
            ({ gdprCookieSettings }) => {
                this.setState({ cookieSettings: gdprCookieSettings });
            }
        );
    }

    render() {
        const {
            cookieGroups, isCookieSet, isLoading, isStatic, cookieSettings
        } = this.state;
        const { isCookieBarActive } = this.props;

        if (isCrawler() || isSSR()) {
            // disable popup for crawlers so page content is not blocked and page is scrollable
            // eslint-disable-next-line no-useless-return
            return null;
        }

        return (
            <GdprCookiePopup
              cookieGroups={ cookieGroups }
              isCookieSet={ isCookieSet }
              isLoading={ isLoading }
              isStatic={ isStatic }
              isCookieBarActive={ isCookieBarActive }
              cookieSettings={ cookieSettings }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookiePopupContainer);
