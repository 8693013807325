/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import GiftCardForm from './GiftCardForm.component';

/** @namespace Giftcard/Component/GiftCardForm/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Giftcard/Component/GiftCardForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup('GiftCardPreview', payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardForm);
