/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import getStore from 'Util/Store';

export * from 'SourceUtil/Media/Media';

export default (src, subPath = '', isMediaPath = true) => {
    // If isMediaPath is passed return local media path

    const { ConfigReducer: { secure_base_media_url, base_url } } = getStore().getState();
    const mediaBaseUrl = process.env.NODE_ENV === 'production' ? secure_base_media_url : `${base_url}/media/`;
    const baseUrl = isMediaPath
        ? mediaBaseUrl || '/media/'
        : base_url;

    return `${ baseUrl }${ subPath }${ src }`;
};
