import master from './images/masterCardIcon.svg';
import paypal from './images/paypalIcon.svg';
import visa from './images/visaIcon.svg';

export * from 'SourceComponent/Footer/Footer.config.js';

export const HAIRY_BABY_COPYRIGHT = 'copyright-block';

export const PAY_METHODS = [
    {
        title: 'MasterCard',
        src: master,
        height: '27px'
    },
    {
        title: 'PayPal',
        src: paypal,
        height: '20px'
    },
    {
        src: visa,
        title: 'Visa',
        height: '14px'
    }
];
