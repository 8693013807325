import { Field } from 'Util/Query';

export class ProductListQueryPlugin {

    addProductLabelFields = (args, callback) => {
        return [
            ...callback(...args),
            this._getLabelFields()
        ];
    }

    _getLabelFields() {
        return new Field('mp_label_data').addFieldList(this._getProductItemsLabelFields());
    }

    _getProductItemsLabelFields() {
        return [
            'name',
            'label',
            'label_color',
            'label_css',
            'label_font',
            'label_font_size',
            'label_image',
            'label_template',
            'label_position',
            'label_position_grid',
            'list_label',
            'list_color',
            'list_css',
            'list_font',
            'list_font_size',
            'list_image',
            'list_template',
            'list_position',
            'list_position_grid',
            'enabled',
            'same'
        ];
    }
}

const { addProductLabelFields } = new ProductListQueryPlugin();

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: addProductLabelFields
        }
    }
};
