/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Breadcrumb from 'Component/Breadcrumb';
import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import './Breadcrumbs.style';

/**
 * Breadcrumbs
 * @class Breadcrumbs
 * @namespace Scandipwa/Component/Breadcrumbs/Component/BreadcrumbsComponent
 */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    renderBreadcrumb({ url, name }, i) {
        const { breadcrumbs } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;

        return (
            <Breadcrumb
              name={ name }
              url={ url }
              index={ i + 1 }
              key={ i }
              isDisabled={ isDisabled }
            />
        );
    }
}

export default BreadcrumbsComponent;
