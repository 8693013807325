/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 */
import { Field } from 'Util/Query';

/** @namespace Giftcard/Query/GiftCard/Query/GiftCardQuery */
export class GiftCardQuery {
    getAppliedGiftCardsQuery(guestCartId) {
        return new Field('getAppliedGiftCards')
            .addArgument('guestCartId', 'String', guestCartId)
            .addField(new Field('status'))
            .addField(new Field('message'));
    }

    getGiftCardsQuery() {
        return new Field('getCustomerGiftCards')
            .addField(new Field('data'));
    }

    getGiftCardsImages(sku) {
        return new Field('getGiftCardImages')
            .addArgument('sku', 'String!', sku);
    }

    addMyAccountGiftCardMutation(giftCardCode) {
        return new Field('addMyAccountGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addField(new Field('status'))
            .addField(new Field('message'));
    }

    removeMyAccountGiftCardMutation(giftCardId) {
        return new Field('removeMyAccountGiftCard')
            .addArgument('giftCardId', 'Int!', giftCardId)
            .addField(new Field('status'))
            .addField(new Field('message'));
    }

    addCheckoutGiftCardMutation(giftCardCode, guestCartId) {
        return new Field('addCheckoutGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addArgument('guestCartId', 'String', guestCartId)
            .addField(new Field('status'))
            .addField(new Field('message'));
    }

    checkCheckoutGiftCardMutation(giftCardCode) {
        return new Field('checkCheckoutGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addField(new Field('id'))
            .addField(new Field('status'))
            .addField(new Field('code'))
            .addField(new Field('balance'))
            .addField(new Field('expiredDate'));
    }

    removeCheckoutGiftCardMutation(giftCardCode, guestCartId) {
        return new Field('removeCheckoutGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addArgument('guestCartId', 'String', guestCartId)
            .addField(new Field('status'))
            .addField(new Field('message'));
    }
}

export default new GiftCardQuery();
