/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Suspense } from 'react';

import {
    SearchField as SourceSearchField,
    SearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SearchField.override.style';

export {
    SearchOverlay
};

/** @namespace Scandipwa/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends SourceSearchField {
    onClickSearchIcon = () => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };

    renderSearchIcon() {
        const { showSearch } = this.state;

        if (showSearch) {
            return (
                <div
                  block="SearchField"
                  elem="CloseIcon"
                  role="button"
                  tabIndex="0"
                  onClick={ this.closeSearch }
                  aria-label={ __('Close') }
                />
            );
        }

        return (
            <div
              block="SearchField"
              elem="Icon"
              role="button"
              tabIndex="0"
              onClick={ this.openSearch }
              aria-label={ __('Search') }
            >
                { __('Search') }
            </div>
        );
    }

    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive
        } = this.props;

        const { showSearch } = this.state;

        if (!showSearch) {
            return null;
        }

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                <div
                  block="SearchField"
                  htmlFor="search-field"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  onClick={ this.onClickSearchIcon }
                  aria-label={ __('Search') }
                />
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay
                      clearSearch={ this.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </div>
        );
    }

    renderMobileContent() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <>
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  placeholder="Search"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                <div
                  block="SearchField"
                  elem="PlaceHolder"
                  role="button"
                  mods={ { isActive, type: 'search' } }
                  onClick={ this.onClickSearchIcon }
                  tabIndex="0"
                  aria-label={ __('Search') }
                />
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay clearSearch={ this.clearSearch } searchCriteria={ searchCriteria } />
                </Suspense>
            </>
        );
    }
}

export default SearchFieldComponent;
