import {
    NewsletterSubscription as SourceNewsletterSubscription
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.override.style';

/** @namespace Scandipwa/Component/NewsletterSubscription/Component/NewsletterSubscriptionComponent */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const fields = {
            newsletterEmail: {
                validation: ['notEmpty', 'email'],
                placeholder: __('Email address'),
                'aria-label': __('Email address')
            }
        };

        return fields;
    }

    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'FieldForm', elem: 'Button' } }
              aria-label={ __('Submit') }
            >
                { __('OK!') }
            </button>
        );
    }
}

export default NewsletterSubscriptionComponent;
