/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';
import { fetchQuery } from 'Util/Request';

import GiftCardQuery from '../../query/GiftCard.query';
import CardDesignSelection from './CardDesignSelection.component';

/** @namespace Giftcard/Component/CardDesignSelection/Container/CardDesignSelectionContainer */
export class CardDesignSelectionContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        updateGiftCardDesign: PropTypes.func.isRequired,
        updateGiftCardOptions: PropTypes.func.isRequired
    };

    containerFunctions = {
        changeInput: this.changeInput.bind(this)
    };

    state = {
        isLoading: true,
        images: [],
        error: null,
        selectedImageId: null
    };

    componentDidMount() {
        this.getImages();
    }

    componentDidUpdate() {
        const { images, selectedImageId } = this.state;

        if (images.length && !selectedImageId) {
            const { id, src } = images[0];

            this.changeInput(id, src);
        }
    }

    async getImages() {
        this.setLoadingStatus(true);

        const {
            product: {
                sku
            },
            updateGiftCardDesign
        } = this.props;

        await fetchQuery(GiftCardQuery.getGiftCardsImages(sku)).then(
            /** @namespace Giftcard/Component/CardDesignSelection/Container/fetchQuery/then */
            ({ getGiftCardImages }) => {
                const images = JSON.parse(getGiftCardImages);

                updateGiftCardDesign(images[0].src);
                this.setState({ images });
            },
            /** @namespace Giftcard/Component/CardDesignSelection/Container/fetchQuery/then */
            (error) => {
                this.setState({ error });
            }
        );

        this.setLoadingStatus();
    }

    containerProps = () => {
        const {
            images,
            selectedImageId
        } = this.state;

        return {
            images,
            selectedImageId
        };
    };

    setLoadingStatus(status = false) {
        this.setState({ isLoading: status });
    }

    changeInput(id, src) {
        const {
            updateGiftCardDesign,
            updateGiftCardOptions
        } = this.props;

        this.setState({ selectedImageId: id });

        updateGiftCardOptions({
            am_giftcard_image: id,
            am_giftcard_custom_image: ''
        });
        updateGiftCardDesign(src);
    }

    render() {
        return (
            <CardDesignSelection
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default CardDesignSelectionContainer;
