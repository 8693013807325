/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import CartQuery from 'Query/Cart.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import { getExtensionAttributes } from '../util/GiftCard';

export const addProductToCart = async (args, callback, instance) => {
    const [
        dispatch,
        {
            product,
            quantity,
            productOptionsData,
            buyRequest
        }
    ] = args;

    const {
        sku,
        type_id: product_type,
        gift_card_options
    } = product;

    if (!gift_card_options) {
        return callback(...args);
    }

    const {
        productOptions,
        productOptionsMulti,
        downloadableLinks
    } = productOptionsData || {};

    const productToAdd = {
        sku,
        product_type,
        quantity,
        product_option: {
            buy_request: buyRequest,
            extension_attributes: getExtensionAttributes(
                {
                    ...product,
                    productOptions,
                    productOptionsMulti,
                    downloadableLinks,
                    gift_card_options
                }
            )
        }
    };

    if (instance._canBeAdded(args[1])) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return Promise.reject();
            }

            const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getSaveCartItemMutation(productToAdd, guestQuoteId)
            );

            return instance._updateCartData(cartData, dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }

    return Promise.reject();
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart
        }
    }
};
