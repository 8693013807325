/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { combineReducers } from 'redux';

import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore/index';

export * from 'SourceStore/index';

/** @namespace Store/Index/getReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers()
});

export function createReducer(asyncReducers) {
    return combineReducers({
        ...getStaticReducers(),
        ...asyncReducers
    });
}

export { default } from 'SourceStore';
