/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';
import { formatPrice } from 'Util/Price';

import GiftCardActions from './GiftCardActions.component';

import './GiftCardActions.style';

/** @namespace Giftcard/Component/GiftCardActions/Container/GiftCardActionsContainer */
export class GiftCardActionsContainer extends PureComponent {
    static propTypes = {
        updateGiftCardOptions: PropTypes.func.isRequired,
        product: ProductType.isRequired
    };

    containerFunctions = {
        setGiftCardValue: this.setGiftCardValue.bind(this)
    };

    state = {
        selectedPrice: '0'
    };

    componentDidUpdate() {
        const {
            product: {
                am_giftcard_prices = [],
                gift_card_options: {
                    am_giftcard_amount
                }
            }
        } = this.props;
        const { selectedPrice } = this.state;

        if (!am_giftcard_amount && am_giftcard_prices.length && selectedPrice === '0') {
            this.setSelectedInitialPrice();
        }
    }

    containerProps = () => {
        const {
            product,
            updateGiftCardOptions
        } = this.props;
        const { selectedPrice } = this.state;

        return {
            product,
            updateGiftCardOptions,
            selectOptions: this.getSelectOptions(),
            selectedValue: selectedPrice
        };
    };

    setGiftCardValue(value) {
        const { updateGiftCardOptions } = this.props;
        const { selectedPrice } = this.state;

        if (selectedPrice === value) {
            updateGiftCardOptions({ am_giftcard_amount: value });
            this.setState({ selectedPrice: 0 });
        } else {
            updateGiftCardOptions({ am_giftcard_amount: value });
            this.setState({ selectedPrice: value });
        }
    }

    setSelectedInitialPrice() {
        const {
            product: {
                am_giftcard_prices
            },
            updateGiftCardOptions
        } = this.props;
        const initialPrice = !am_giftcard_prices || !am_giftcard_prices.length
            ? '0' : am_giftcard_prices[0].value.value;

        updateGiftCardOptions({ am_giftcard_amount: initialPrice });
        this.setState({ selectedPrice: initialPrice });
    }

    getSelectOptions = () => {
        const {
            product: {
                am_giftcard_prices = []
            }
        } = this.props;

        return am_giftcard_prices
            .reduce((acc, { price_id, value: { currency, value } }) => {
                acc.push({
                    id: price_id,
                    value,
                    label: formatPrice(value, currency)
                });

                return acc;
            }, []);
    };

    render() {
        return (
            <GiftCardActions
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default GiftCardActionsContainer;
