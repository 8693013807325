/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import { Subscribe } from 'unstated';

import ProductActions from 'SourceComponent/ProductActions';
import ProductGallery from 'SourceComponent/ProductGallery';
import SharedTransitionContainer from 'SourceComponent/SharedTransition/SharedTransition.unstated';

import CardDesignPreview from '../component/CardDesignPreview';

/** @namespace Scandiweb/GiftCardScandiPWA/Plugin/Plugin */
export class ProductPagePlugin {
    state = (originalMember, instance) => ({
        ...originalMember,
        ...instance.props,
        gift_card_options: {
            am_giftcard_amount: 0,
            am_giftcard_amount_custom: 0,
            am_giftcard_type: 1,
            am_giftcard_sender_name: '',
            am_giftcard_recipient_name: '',
            am_giftcard_recipient_email: '',
            am_giftcard_message: '',
            is_date_delivery: false,
            am_giftcard_date_delivery: '',
            am_giftcard_date_delivery_timezone: '',
            am_giftcard_image: 1,
            am_giftcard_custom_image: ''
        }
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        updateGiftCardDesign: instance.updateGiftCardDesign.bind(instance),
        updateGiftCardOptions: instance.updateGiftCardOptions.bind(instance)
    });

    updateGiftCardDesign = (args, callback, instance) => {
        instance.setState({
            giftCardDesign: args[0]
        });
    };

    updateGiftCardOptions = (args, callback, instance) => {
        instance.setState({
            gift_card_options: {
                ...instance.state.gift_card_options,
                ...args[0]
            }
        });
    };

    renderProductPageContent = (args, callback, instance) => {
        const {
            configurableVariantIndex,
            parameters,
            getLink,
            dataSource,
            updateConfigurableVariant,
            productOrVariant,
            areDetailsLoaded,
            getSelectedCustomizableOptions,
            customizableOptionsData,
            updateGiftCardDesign,
            updateGiftCardOptions,
            giftCardDesign,
            gift_card_options,
            productOptionsData,
            setBundlePrice,
            selectedBundlePrice,
            selectedBundlePriceExclTax,
            setLinkedDownloadables,
            setLinkedDownloadablesPrice,
            selectedLinkPrice
        } = instance.props;

        return (
            <>
                { dataSource.type_id === 'amgiftcard'
                    ? (
                    <Subscribe to={ [SharedTransitionContainer] }>
                    { ({ registerSharedElementDestination }) => (
                        <CardDesignPreview
                          registerSharedElementDestination={ registerSharedElementDestination }
                          product={ dataSource }
                          giftCardDesign={ giftCardDesign }
                        />
                    ) }
                    </Subscribe>
                    )
                    : (
                    <ProductGallery
                      product={ productOrVariant }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                    ) }
                <ProductActions
                  getLink={ getLink }
                  updateConfigurableVariant={ updateConfigurableVariant }
                  product={ { ...dataSource, gift_card_options } }
                  productOrVariant={ productOrVariant }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  configurableVariantIndex={ configurableVariantIndex }
                  getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
                  customizableOptionsData={ customizableOptionsData }
                  updateGiftCardDesign={ updateGiftCardDesign }
                  updateGiftCardOptions={ updateGiftCardOptions }
                  giftCardDesign={ giftCardDesign }
                  productOptionsData={ productOptionsData }
                  setBundlePrice={ setBundlePrice }
                  selectedBundlePrice={ selectedBundlePrice }
                  selectedBundlePriceExclTax={ selectedBundlePriceExclTax }
                  setLinkedDownloadables={ setLinkedDownloadables }
                  setLinkedDownloadablesPrice={ setLinkedDownloadablesPrice }
                  selectedLinkPrice={ selectedLinkPrice }
                />
            </>
        );
    };
}

export const {
    state,
    updateGiftCardOptions,
    updateGiftCardDesign,
    containerFunctions
} = new ProductPagePlugin();
export const { renderProductPageContent } = new ProductPagePlugin();

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductPageContent: [
                {
                    position: 10,
                    implementation: renderProductPageContent
                }
            ]
        }
    },
    'Route/ProductPage/Container': {
        'member-property': {
            containerFunctions: [
                {
                    position: 100,
                    implementation: containerFunctions
                }
            ],
            state: [
                {
                    position: 110,
                    implementation: state
                }
            ]
        },
        'member-function': {
            updateGiftCardDesign: [
                {
                    position: 20,
                    implementation: updateGiftCardDesign
                }
            ],
            updateGiftCardOptions: [
                {
                    position: 100,
                    implementation: updateGiftCardOptions
                }
            ]
        }
    }
};
