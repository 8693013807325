/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ('OSL') v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';

import { getCurrentDate, getMaxDate, getMinDate } from '../../util/Date/Date';

import './ScheduleDelivery.style.scss';

/** @namespace Giftcard/Component/ScheduleDelivery/Component/ScheduleDeliveryComponent */
export class ScheduleDeliveryComponent extends PureComponent {
    static propTypes = {
        updateGiftCardOptions: PropTypes.func.isRequired,
        getListTimezones: PropTypes.string.isRequired
    };

    state = {
        delayDelivery: false,
        selectValue: '',
        deliveryDate: ''
    };

    handleDelivery = (e) => {
        const { updateGiftCardOptions } = this.props;

        const { target: { value } } = e;

        if (value === '1') {
            this.setState({ delayDelivery: true });
            this.setState({ deliveryDate: '' });
            updateGiftCardOptions({ is_date_delivery: true });
        } else {
            this.setState({ delayDelivery: false });
            updateGiftCardOptions({ is_date_delivery: false });
        }
    };

    handleChange = (e) => {
        const {
            updateGiftCardOptions
        } = this.props;
        const { target: { value } } = e;

        updateGiftCardOptions({ am_giftcard_date_delivery: value });
        this.setState({ deliveryDate: value });
    };

    changeSelect = (e) => {
        this.setState({ selectValue: e });
    };

    updateValues = (date, timezone) => {
        const { selectValue } = this.state;
        const { updateGiftCardOptions } = this.props;

        const currentDateValue = date;
        const currentTimeZone = (selectValue) || timezone;

        updateGiftCardOptions({
            am_giftcard_date_delivery: currentDateValue,
            am_giftcard_date_delivery_timezone: currentTimeZone
        });

        this.setState({ selectValue: currentTimeZone });
        this.setState({ deliveryDate: currentDateValue });
    };

    renderScheduleDelivery() {
        const { delayDelivery } = this.state;

        return (
            <>
                <div block="ScheduleDelivery" elem="Title">
                    { __('Schedule delivery') }
                </div>
                <div block="ScheduleDelivery" elem="Wrapper">
                    <span block="ScheduleDelivery" elem="Element">
                        <Field
                          block="ScheduleDelivery"
                          elem="Radio"
                          name="scheduleDelivery"
                          label={ __('Send now') }
                          id="straight"
                          type="radio"
                          value="0"
                          checked={ !delayDelivery }
                          onClick={ this.handleDelivery }
                        />
                    </span>
                    <span block="ScheduleDelivery" elem="Element">
                        <Field
                          block="ScheduleDelivery"
                          elem="Radio"
                          label={ __('Send later') }
                          name="scheduleDelivery"
                          id="delayed"
                          type="radio"
                          value="1"
                          checked={ delayDelivery }
                          onClick={ this.handleDelivery }
                        />
                    </span>
                </div>
            </>
        );
    }

    renderDelayDelivery() {
        const {
            getListTimezones = ''
        } = this.props;

        const {
            selectValue,
            delayDelivery,
            deliveryDate
        } = this.state;

        if (!getListTimezones) {
            return null;
        }

        const selectOptions = JSON.parse(getListTimezones).map(({ label, value }) => ({
            label, value, id: value
        }));

        const timeZoneValue = (selectValue) || selectOptions[0].value;
        const currentDate = delayDelivery
            ? deliveryDate
            : getCurrentDate();

        this.updateValues(currentDate, timeZoneValue);

        if (!delayDelivery) {
            return null;
        }

        return (
            <div>
                <div
                  block="ScheduleDelivery"
                  elem="Date"
                >
                    <label
                      block="ScheduleDelivery"
                      elem="DateLabel"
                      htmlFor="deliveryDate"
                    >
                        { __('Delivery Date') }
                    </label>
                    <input
                      block="ScheduleDelivery"
                      elem="DateSelect"
                      name="deliveryDate"
                      id="deliveryDate"
                      type="date"
                      min={ getMinDate(0) }
                      max={ getMaxDate(2) }
                      onChange={ this.handleChange }
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="ScheduleDelivery">
                { this.renderScheduleDelivery() }
                { this.renderDelayDelivery() }
            </div>
        );
    }
}

export default ScheduleDeliveryComponent;
