/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Manuel Trinidad <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart/Token';
import { fetchMutation, fetchQuery } from 'Util/Request';

import GiftCardQuery from '../../query/GiftCard.query';
import CheckoutGiftCardInput from './CheckoutGiftCardInput.component';

import './CheckoutGiftCardInput.style.scss';

/** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateInitialCartData: () => CartDispatcher.updateInitialCartData(dispatch)
});

/** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/CheckoutGiftCardInputContainer */
export class CheckoutGiftCardInputContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired,
        updatePaymentMethods: PropTypes.func
    };

    static defaultProps = {
        updatePaymentMethods: () => {}
    };

    state = {
        checkedCode: {},
        giftCards: [],
        value: ''
    };

    containerFunctions = ({
        handleGiftCardCheck: this.handleGiftCardCheck.bind(this),
        handleGiftCardRemove: this.handleGiftCardRemove.bind(this),
        handleGiftCardSubmit: this.handleGiftCardSubmit.bind(this),
        handleGiftCardCodeChange: this.handleGiftCardCodeChange.bind(this)
    });

    componentDidMount() {
        this.getAppliedGiftCards();
    }

    containerProps = () => {
        const {
            checkedCode,
            giftCards,
            value
        } = this.state;

        return {
            checkedCode,
            giftCards,
            value
        };
    };

    handleGiftCardCodeChange(value) {
        this.setState({ value });
    }

    async getAppliedGiftCards() {
        const { showNotification } = this.props;

        return fetchQuery(GiftCardQuery.getAppliedGiftCardsQuery(
            BrowserDatabase.getItem('guest_quote_id').token
        )).then(
            /** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/fetchQuery/then */
            ({ getAppliedGiftCards: { message } }) => {
                this.setState({
                    giftCards: JSON.parse(message)
                });
            },
            /** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/fetchQuery/then */
            (error) => showNotification('error', error[0].message)
        );
    }

    async handleGiftCardCheck(value) {
        const { showNotification } = this.props;

        return fetchMutation(GiftCardQuery.checkCheckoutGiftCardMutation(
            value
        )).then(
            /** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/fetchMutation/then */
            ({ checkCheckoutGiftCard }) => {
                this.setState({ checkedCode: checkCheckoutGiftCard });
            },
            /** @namespace Giftcard/Component/CheckoutGiftCardInput/Container/fetchMutation/then */
            (error) => showNotification('error', error[0].message)
        );
    }

    async handleGiftCardRemove(e, code) {
        const { showNotification, updateInitialCartData, updatePaymentMethods } = this.props;
        const { giftCards: prevGiftCards } = this.state;

        e.preventDefault();
        e.stopPropagation();

        try {
            const result = await fetchMutation(GiftCardQuery.removeCheckoutGiftCardMutation(
                code,
                getGuestQuoteId()
            ));

            const { removeCheckoutGiftCard: { message = '' } = {} } = result;

            updateInitialCartData();
            await this.getAppliedGiftCards();
            showNotification('success', message);

            const giftCardIndex = prevGiftCards.findIndex(({ code: cardCode }) => cardCode === code);
            const giftCards = Array.from(prevGiftCards);
            giftCards.splice(giftCardIndex, 1);

            this.setState({
                value: '',
                giftCards
            });

            updatePaymentMethods();

            return result;
        } catch (errors) {
            const error = errors.length ? errors[0] : errors;
            showNotification('error', error.message);
        }

        return null;
    }

    async handleGiftCardSubmit(code) {
        const { showNotification, updateInitialCartData, updatePaymentMethods } = this.props;

        try {
            const result = await fetchMutation(GiftCardQuery.addCheckoutGiftCardMutation(
                code,
                getGuestQuoteId()
            ));

            const { addCheckoutGiftCard: { message = '' } = {} } = result;

            updateInitialCartData();
            await this.getAppliedGiftCards();
            showNotification('success', message);
            updatePaymentMethods();

            return result;
        } catch (errors) {
            const error = errors.length ? errors[0] : errors;
            showNotification('error', error.message);
        }

        return null;
    }

    render() {
        return (
            <CheckoutGiftCardInput
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapDispatchToProps, mapDispatchToProps)(CheckoutGiftCardInputContainer);
