/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Input from 'Component/FieldInput';
import Image from 'Component/Image';

import { ImageType } from '../../type/GiftCard';

import './CardDesignSelection.style.scss';

/** @namespace Giftcard/Component/CardDesignSelection/Component/CardDesignSelectionComponent */
export class CardDesignSelectionComponent extends PureComponent {
    static propTypes = {
        changeInput: PropTypes.func.isRequired,
        images: PropTypes.arrayOf(ImageType).isRequired,
        selectedImageId: PropTypes.number
    };

    static defaultProps = {
        selectedImageId: null
    };

    renderItem(item) {
        const {
            changeInput,
            selectedImageId
        } = this.props;
        const { id, src } = item;

        return (
            <div key={ id }>
                <Input
                  block="CardDesignSelection"
                  elem="Input"
                  name="design"
                  type="radio"
                  value={ id }
                  id={ `design-${ id }` }
                  checked={ id === selectedImageId }
                  onChange={ () => changeInput(id, src) }
                />
                <label
                  block="CardDesignSelection"
                  elem="Label"
                  htmlFor={ `design-${ id }` }
                >
                    <Image
                      src={ src }
                      alt="Gift Card Image"
                      mix={ {
                          block: 'CardDesignSelection',
                          elem: 'Image'
                      } }
                    />
                </label>
            </div>
        );
    }

    mapDesign() {
        const { images } = this.props;

        if (!images) {
            return null;
        }

        return (
            images.map((image) => this.renderItem(image))
        );
    }

    render() {
        return (
            <div className="CardDesignSelection">
                <div block="CardDesignSelection" elem="Title">
                    { __('Choose card design:') }
                </div>
                <div block="CardDesignSelection" elem="Wrapper">
                    { this.mapDesign() }
                </div>
            </div>
        );
    }
}

export default CardDesignSelectionComponent;
