/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 */

import {
    BUNDLE,
    CONFIGURABLE,
    DOWNLOADABLE,
    SIMPLE,
    VIRTUAL
} from 'Util/Product';

/** @namespace Giftcard/Util/GiftCard/getExtensionAttributes */
export const getExtensionAttributes = (product) => {
    const {
        configurable_options,
        configurableVariantIndex,
        productOptions,
        productOptionsMulti,
        downloadableLinks,
        variants,
        type_id,
        gift_card_options
    } = product;

    if (type_id === CONFIGURABLE) {
        const { attributes = {} } = variants[configurableVariantIndex] || {};
        const properties = {
            configurable_item_options: Object.values(configurable_options)
                .reduce((prev, { attribute_id, attribute_code }) => {
                    const {
                        attribute_value,
                        attribute_id: attrId
                    } = attributes[attribute_code] || {};

                    if (attribute_value) {
                        return [
                            ...prev,
                            {
                                option_id: attribute_id || attrId,
                                option_value: attribute_value
                            }
                        ];
                    }

                    return prev;
                }, [])
        };

        if (productOptions) {
            properties.customizable_options = productOptions;
        }
        if (productOptionsMulti) {
            properties.customizable_options_multi = productOptionsMulti;
        }

        return properties;
    }

    if (type_id === BUNDLE && (productOptions || productOptionsMulti)) {
        return { bundle_options: Array.from(productOptions || []) };
    }

    if ((type_id === SIMPLE || type_id === VIRTUAL) && (productOptions || productOptionsMulti)) {
        return {
            customizable_options: productOptions || [],
            customizable_options_multi: productOptionsMulti || []
        };
    }

    if (type_id === DOWNLOADABLE && downloadableLinks) {
        return {
            downloadable_product_links: downloadableLinks
        };
    }

    if (type_id === 'amgiftcard' && gift_card_options) {
        return {
            gift_card_options
        };
    }

    return {};
};
