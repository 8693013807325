/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, fetchQuery } from 'Util/Request';

import GiftCardQuery from '../../query/GiftCard.query';
import MyAccountGiftCardInput from './MyAccountGiftCardInput.component';

/** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/MyAccountGiftCardInputContainer */
export class MyAccountGiftCardInputContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired
    };

    state = {
        isLoading: false,
        giftCards: []
    };

    containerFunctions = ({
        handleGiftCardRemove: this.handleGiftCardRemove.bind(this),
        handleGiftCardSubmit: this.handleGiftCardSubmit.bind(this)
    });

    componentDidMount() {
        this.getAppliedGiftCards();
    }

    containerProps = () => {
        const { isLoading, giftCards } = this.state;

        return { isLoading, giftCards };
    };

    async getAppliedGiftCards() {
        const { showNotification } = this.props;

        this.setState(
            { isLoading: true },
            () => {
                fetchQuery(GiftCardQuery.getGiftCardsQuery())
                    .then(
                        /** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/fetchQuery/then */
                        ({ getCustomerGiftCards: { data } }) => {
                            if (data === null) {
                                showNotification('error', 'No data');
                            } else {
                                this.setState({ giftCards: JSON.parse(data) });
                            }

                            this.setState({ isLoading: false });
                        },
                        /** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/fetchQuery/then */
                        (error) => {
                            this.setState({ isLoading: false });
                            showNotification('error', error[0].message);
                        }
                    );
            }
        );
    }

    async handleGiftCardRemove(giftCardId) {
        const { showNotification } = this.props;

        this.setState(
            { isLoading: true },
            () => {
                fetchMutation(GiftCardQuery.removeMyAccountGiftCardMutation(giftCardId))
                    .then(
                        /** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/fetchMutation/then */
                        ({ removeMyAccountGiftCard: { status, message } }) => {
                            if (!status) {
                                showNotification('error', message);
                            } else {
                                this.getAppliedGiftCards();
                                showNotification('success', message);
                            }

                            this.setState({ isLoading: false });
                        },
                        /** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/fetchMutation/then */
                        (error) => {
                            this.setState({ isLoading: false });
                            showNotification('error', error[0].message);
                        }
                    );
            }
        );
    }

    async handleGiftCardSubmit(value) {
        const { showNotification } = this.props;

        this.setState(
            { isLoading: true },
            () => {
                fetchMutation(GiftCardQuery.addMyAccountGiftCardMutation(value))
                    .then(
                        /** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/fetchMutation/then */
                        ({ addMyAccountGiftCard: { status, message } }) => {
                            if (!status) {
                                showNotification('error', message);
                            } else {
                                this.getAppliedGiftCards();
                                showNotification('success', message);
                            }

                            this.setState({ isLoading: false });
                        },
                        /** @namespace Giftcard/Component/MyAccountGiftCardInput/Container/fetchMutation/then */
                        (error) => {
                            this.setState({ isLoading: false });
                            showNotification('error', error[0].message);
                        }
                    );
            }
        );
    }

    render() {
        return (
            <MyAccountGiftCardInput
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapDispatchToProps, mapDispatchToProps)(MyAccountGiftCardInputContainer);
